import { getUserInfo, verifyToken } from '@/services/login'
import { delCookie, getCookie } from '@/utils/utils'
import { Effect, Reducer, Subscription } from 'umi'
import menusSource from '../../config/menu.config'
import { LoginUserInfoState, MenusData } from './connect.d'

export interface GlobalModelState {
	name: string
	menusData: MenusData[]
	userInfo: LoginUserInfoState
}

export interface GlobalModelType {
	namespace: 'global'
	state: GlobalModelState
	effects: {
		queryUserInfo: Effect
		checkToken: Effect
	}
	reducers: {
		save: Reducer<GlobalModelState>
		// 启用 immer 之后
		// save: ImmerReducer<GlobalModelState>;
	}
	subscriptions: { setup: Subscription }
}

const GlobalModel: GlobalModelType = {
	namespace: 'global',
	state: {
		name: '',
		menusData: menusSource,
		userInfo: {
			id: '',
			userName: '',
			phoneNo: '',
		},
	},
	effects: {
		*queryUserInfo({ payload }, { call, put }) {
			let token = getCookie('accesstoken')
			if (!token) {
				return
			}
			const response = yield call(getUserInfo, { ...payload })
			// console.log('userInfo', response)
			if (response?.code === 200) {
				yield put({
					type: 'save',
					payload: {
						userInfo: response.value,
					},
				})
			} else {
				yield put({
					type: 'save',
					payload: {
						userInfo: { id: '', userName: '', phoneNo: '' },
					},
				})
			}
			delCookie('accesstoken')
		},
		*checkToken({ payload }, { call, put }) {
			const response = yield call(verifyToken, { ...payload })
			if (response.success === true) {
			} else {
				delCookie('accesstoken')
			}
		},
	},
	reducers: {
		save(state, action) {
			return {
				...state,
				...action.payload,
			}
		},
		// 启用 immer 之后
		// save(state, action) {
		//   state.name = action.payload;
		// },
	},
	subscriptions: {
		setup({ dispatch, history }) {
			return history.listen(({ pathname }) => {
				const reg = /^\/login/g
				if (!reg.test(pathname)) {
					dispatch({
						type: 'queryUserInfo',
					})
					// dispatch({
					// 	type: 'checkToken',
					// 	payload: {
					// 		token: getCookie('accesstoken'),
					// 		terminal: 'pc',
					// 	},
					// })
				}
			})
		},
	},
}

export default GlobalModel
