import request from '@/utils/request'
import checkEnv from '@/services/baseUrl'

// 定义用户注册请求参数
export interface UserRegisterParamsType {
	userName?: any
	phoneNo: any
	verificationCode: any
	password: any
}

// 用户注册请求接口
export async function userRegister(params: UserRegisterParamsType) {
	return request(`${checkEnv().BASEURL}/vbim-user/user/userRegist`, {
		method: 'POST',
		data: params,
	})
}

export interface ReleaseMeetingUserParamsType {
	userName?: any
	companyName?: any
	phone: any
	positionName: any
}

// 用户注册请求接口
export async function saveReleaseMeetingUser(
	params: ReleaseMeetingUserParamsType,
) {
	return request(
		`${checkEnv().BASEURL}/vbim-user/official/saveReleaseMeetingUser`,
		{
			method: 'POST',
			data: params,
		},
	)
}

// 定义发送短信验证码请求参数
export interface SendShortMsgParamsType {
	phoneNo: string
	motion: string
	// 操作类型
	// 注册发送消息: register
	// 重置密码发送消息: resetMm
	// 绑定手机号发送消息: modifyPhone
	// 手机登录发送信息: login
	// 绑定手机发送消息: bindPhone
	// 用户设置解锁发送消息 unlock
}

// 发送短信验证码请求接口
export async function sendShortMsg(params: SendShortMsgParamsType) {
	return request(`${checkEnv().BASEURL}/vbim-user/user/sendShortMessage`, {
		method: 'POST',
		data: params,
	})
}

// 定义忘记密码1-验证短信验证码请求参数
export interface ForgetPwdVerifyParamsType {
	phoneNo: string
	verificationCode: string
}

// 忘记密码1-验证短信验证码请求接口
export async function forgetPwdVerify(params: ForgetPwdVerifyParamsType) {
	return request(`${checkEnv().BASEURL}/vbim-user/user/forgetPwd`, {
		method: 'POST',
		data: params,
	})
}

// 定义忘记密码2-重置密码请求参数
export interface ResetPwdParamsType {
	phoneNo: string
	password: string
}

// 忘记密码2-重置密码请求接口
export async function resetPwd(params: ResetPwdParamsType) {
	return request(`${checkEnv().BASEURL}/vbim-user/user/resetPwd`, {
		method: 'POST',
		data: params,
	})
}

// 定义校验用户token请求参数
export interface VerifyTokenParamsType {
	token: string
	terminal: string //  "访问终端：pc、app、mini（必填）"
}

// 校验用户token请求接口
export async function verifyToken(params: VerifyTokenParamsType) {
	return request(
		`${checkEnv().BASEURL}/vbim-user/user/parsingToken?token=${
			params.token
		}&terminal=${params.terminal}`,
		{
			method: 'POST',
			data: params,
		},
	)
}

// 获取用户信息请求接口
export async function getUserInfo() {
	return request(`${checkEnv().BASEURL}/vbim-user/user/getUserInfo`, {
		method: 'POST',
	})
}

// 校验用户退出请求接口
export async function logout() {
	return request(`${checkEnv().BASEURL}/vbim-user/user/loginOut`, {
		method: 'GET',
	})
}

// 定义用户登录请求参数
export interface LoginParamsType {
	loginType: string // "登录类型（1：账号密码登录；2：手机号登录）",
	userAccount?: string // :"账号",
	phoneNo?: string // 手机号,
	password?: string //"密码Aes加密串",
	verificationCode?: string //"手机短信验证码"
}

// 用户登录请求接口
export async function queryLogin(params: LoginParamsType) {
	return request(`${checkEnv().BASEURL}/vbim-user/user/pc_login.do`, {
		method: 'POST',
		data: params,
	})
}

// 立即合作(立即体验)-用户留资料-校验用户是否存在请求参数
export interface LeaveInfoVerifyParamsType {
	phone: string
}

// 立即合作(立即体验)-用户留资料-校验用户是否存在接口
export async function leaveInfoVerify(params: LeaveInfoVerifyParamsType) {
	return request(`${checkEnv().BASEURL}/vbim-user/official/validUserPhone`, {
		method: 'POST',
		data: params,
	})
}

// 立即合作(立即体验)-用户留资料-提交用户资料请求参数
export interface LeaveInfoSubmitParamsType {
	nickName: string // 昵称
	phone: string // 手机号
	code?: string // 验证码
	password?: string // 密码
	enterpriseName: string // 公司名称
	mail: string // 邮箱
}

// 立即合作(立即体验)-用户留资料-提交用户资料接口
export async function leaveInfoSubmit(params: LeaveInfoSubmitParamsType) {
	return request(`${checkEnv().BASEURL}/vbim-user/official/saveOfficialUser`, {
		method: 'POST',
		data: params,
	})
}

// 轮询用户微信登录状态请求参数
export interface GetWXLoginStatusParamsType {
	state: any
}

// 轮询用户微信登录状态接口
export async function getWXLoginStatus(params: GetWXLoginStatusParamsType) {
	return request(`${checkEnv().BASEURL}/vbim-user/weChat/getLoginAccessToken`, {
		method: 'POST',
		data: params,
	})
}

// 微信关联请求参数
export interface makeWXRelationshipParamsType {
	loginType: number // 1:账号密码 2.手机号验证码
	state: number // 微信扫码登录时生成的userId
	userAccount?: string
	password?: string
	phoneNo?: string
	verificationCode?: string
}

// 微信关联接口
export async function makeWXRelationship(params: makeWXRelationshipParamsType) {
	return request(`${checkEnv().BASEURL}/vbim-user/user/userRelation.do`, {
		method: 'POST',
		data: params,
	})
}
