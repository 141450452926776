/* global zhuge */
/*******
 * @description: 诸葛识别用户
 * @param {*} userId  用户身份标识
 * @param {*} params  其它用户详情,可自定义
 * @param {*} callback  回调函数,可不传
 * @return {*}
 * @use: 诸葛识别用户
 */
const identifyUser = (userId: any, params = {}, callback: Function) => {
	try {
		zhuge.identify(
			userId,
			{
				projectName: '擎天柱官网',
				...params,
			},
			function() {
				if (callback && typeof callback === 'function') {
					callback()
				}
			},
		)
	} catch (error) {
		console.log(error)
	}
}

/*******
 * @description: 诸葛记录事件
 * @param {*} eventName  事件名称
 * @param {*} params  事件详情(可自定义,但属性名称和属性值分别不能超过255和200个字符)
 * @param {*} callback  回调函数
 * @return {*}
 * @use:  诸葛记录事件
 */
const recordEvent = (eventName: string, params = {}, callback: Function) => {
	try {
		zhuge.track(
			eventName,
			{
				projectName: '擎天柱官网',
				...params,
			},
			function() {
				if (callback && typeof callback === 'function') {
					callback()
				}
			},
		)
	} catch (error) {
		console.log(error)
	}
}

/*******
 * @description: 若有⼀一些属性对于您来说，每⼀一个事件都要拥有，那么您可以调⽤用setSuperProperty将它传⼊入
 * @param {*} params {'属性名': '属性值'}
 * @return {*}
 * @use:设置所有事件的通用属性
 */
const setEventCommonProperty = (params = {}) => {
	try {
		zhuge.setSuperProperty({
			projectName: '擎天柱官网',
			...params,
		})
	} catch (error) {
		console.log(error)
	}
}

/*******
 * @description: 设置⽤用户属性
 * @param {*} params {'属性名': '属性值'}
 * @return {*}
 * @use:设置⽤用户属性
 */
const setUserCommonProperty = (params = {}) => {
	try {
		zhuge.setUserProperties({
			projectName: '擎天柱官网',
			...params,
		})
	} catch (error) {
		console.log(error)
	}
}

export {
	identifyUser,
	recordEvent,
	setEventCommonProperty,
	setUserCommonProperty,
}
