// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/vanke_llw/Desktop/Code/vtwinning/node_modules/_@umijs_runtime@3.5.28@@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'@/pages/home'), loading: LoadingComponent})
      },
      {
        "path": "/login",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login' */'@/pages/login'), loading: LoadingComponent})
      },
      {
        "path": "/wechatRelation",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wechatRelation' */'@/pages/wechatRelation'), loading: LoadingComponent})
      },
      {
        "path": "/register",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__register' */'@/pages/register'), loading: LoadingComponent})
      },
      {
        "path": "/resetPwd",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resetPwd' */'@/pages/resetPwd'), loading: LoadingComponent})
      },
      {
        "path": "/dataService",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataService' */'@/pages/dataService'), loading: LoadingComponent})
      },
      {
        "path": "/aboutUS",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__aboutUS' */'@/pages/aboutUS'), loading: LoadingComponent})
      },
      {
        "path": "/digitalGarage",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__digitalGarage' */'@/pages/digitalGarage'), loading: LoadingComponent})
      },
      {
        "path": "/digitalSandbox",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__digitalSandbox' */'@/pages/digitalSandbox'), loading: LoadingComponent})
      },
      {
        "path": "/digitalExhi",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__digitalExhi' */'@/pages/digitalExhi'), loading: LoadingComponent})
      },
      {
        "path": "/digitalTourism",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__digitalTourism' */'@/pages/digitalTourism'), loading: LoadingComponent})
      },
      {
        "path": "/news",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__news' */'@/pages/news'), loading: LoadingComponent})
      },
      {
        "path": "/wingParking",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wingParking' */'@/pages/wingParking'), loading: LoadingComponent})
      },
      {
        "path": "/salesmanTool",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__salesmanTool' */'@/pages/salesmanTool'), loading: LoadingComponent})
      },
      {
        "path": "/404",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent})
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
