import checkEnv from '@/services/baseUrl'
import {
	leaveInfoSubmit,
	logout,
	makeWXRelationship,
	queryLogin,
	saveReleaseMeetingUser,
} from '@/services/login'
import { delCookie, getUrlParams, setCookie } from '@/utils/utils'
import { message, notification } from 'antd'
import { Effect, history, Reducer } from 'umi'
import { LoginUserInfoState } from './connect.d'

export interface LoginModelState {
	userInfo: LoginUserInfoState
	isError: boolean
	userId: any
	redirect_url: string
}

export interface LoginModelType {
	namespace: 'login'
	state: LoginModelState
	effects: {
		leaveInfo: Effect
		saveReleaseMeetingUser: Effect
		queryLogin: Effect
		logout: Effect
		redirect: Effect
		wechatRelation: Effect
	}
	reducers: {
		save: Reducer<LoginModelState>
		// 启用 immer 之后
		// save: ImmerReducer<LoginModelState>;
	}
}

const LoginModel: LoginModelType = {
	namespace: 'login',
	state: {
		userInfo: {
			id: '',
			userName: '',
			phoneNo: '',
		},
		isError: false,
		userId: '',
		redirect_url: '',
	},
	effects: {
		// 登录并跳转首页
		*queryLogin({ payload }, { call }) {
			const response = yield call(queryLogin, { ...payload })
			if (response?.code === 200) {
				setCookie('accesstoken', response.value, 7)
				message.success('登录成功！')
				setTimeout(() => {
					history.replace('/')
				}, 500)
			} else {
				message.error(response.message)
			}
		},
		// 客户留资料
		*leaveInfo({ payload, callback }, { call }) {
			const response = yield call(leaveInfoSubmit, { ...payload })
			if (response?.code === 200) {
				if (callback && typeof callback === 'function') {
					callback()
				}
			} else {
				message.error(response.message)
			}
		},

		// 客户留资料
		*saveReleaseMeetingUser({ payload, callback }, { call }) {
			const response = yield call(saveReleaseMeetingUser, { ...payload })
			if (response) {
				if (callback && typeof callback === 'function') {
					callback(response?.code)
				}
			}
		},
		// 退出登录
		*logout(_, { call, put }) {
			const response = yield call(logout)
			if (response?.code === 200) {
				message.success('您已安全退出')
			}
			yield put({
				type: 'global/save',
				payload: {
					userInfo: { id: '', userName: '', phoneNo: '' },
				},
			})
			delCookie('accesstoken')
		},
		// 重定向跳转到对应页面
		*redirect(_, { select }) {
			const params = getUrlParams()
			let cur_redirect_url = ''
			if (!params) {
				const { redirect_url } = yield select((state: LoginModelState) => state)
				cur_redirect_url = redirect_url
			} else {
				cur_redirect_url = params.redirect_url
			}
			if (cur_redirect_url && cur_redirect_url.includes('http')) {
				cur_redirect_url = decodeURIComponent(cur_redirect_url)
				window.location.href = cur_redirect_url
			} else {
				history.replace('/')
			}
		},
		// 与微信建立账号绑定关系
		*wechatRelation({ payload }, { call, put }) {
			const response = yield call(makeWXRelationship, { ...payload })
			if (response?.code === 200) {
				setCookie('accesstoken', response.value, 7)
				yield put({
					type: 'redirect',
				})
			} else {
				console.log('wechatRelation failed:', response)
				// message.error(response.message)
				notification.error({
					message: '温馨提示',
					duration: 2,
					description: '关联失败',
				})
			}
		},
	},
	reducers: {
		save(state, action) {
			return {
				...state,
				...action.payload,
			}
		},
		// 启用 immer 之后
		// save(state, action) {
		//   state.name = action.payload;
		// },
	},
}

export default LoginModel
