import loadingPng from '@/assets/images/icon_loading.png'
import React from 'react'
import './index.less'

export default () => (
	<div className="pageLoadingBox">
		<img src={loadingPng} alt="" />
		<h1>Loading......</h1>
	</div>
	// <></>
)
