import { recordEvent } from './zhuge'

/**
 * menu Highlight key
 * @param pathname string
 */
export const queryKeysByPath = (
	pathname: string,
): { openKey: string; selectKey: string } => {
	const reg = /(^\/*)|(\/*$)/g // 匹配字符串首尾斜杠
	const path = pathname.replace(reg, '')
	const routes = path.split('/')
	return { openKey: routes[0], selectKey: routes[1] || routes[0] }
}

const domains: string = window.location.href.includes('.vanke.com')
	? '.vanke.com'
	: 'vtwinning.com'
//  设置cookie
export function setCookie(
	cname: string,
	cvalue: any,
	exdays: number,
	domain = domains,
) {
	// console.log('domains=', domains)
	var d = new Date()
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
	var expires = 'expires=' + d.toUTCString()
	document.cookie =
		cname + '=' + cvalue + '; ' + expires + ';domain=' + domain + ';path=/;'
}

//  获取cookie
export function getCookie(cname: string) {
	var arr,
		reg = new RegExp('(^| )' + cname + '=([^;]*)(;|$)')
	if ((arr = document.cookie.match(reg))) return arr[2]
	else return null
}

// 删除cookie
export function delCookie(name: string) {
	var exp = new Date()
	exp.setTime(exp.getTime() - 1)
	var cval = getCookie(name)
	if (cval != null)
		document.cookie = name + '=' + cval + ';expires=' + exp.toUTCString()
}

// 获取浏览器参数 返回JSON对象
export function getUrlParams(linkUrl?: string) {
	let url = linkUrl || window.location.href
	let index = url.indexOf('?')
	if (index == -1) return {}
	let strParams = url.substr(index + 1)
	let strArr = strParams.split('&')
	let json = {}
	strArr.forEach(item => {
		let arr = item.split('=')
		json[arr[0]] = decodeURIComponent(arr[1])
	})
	return json
}

// 平滑返回顶部
export function backToTop() {
	recordEvent('点击底部行业解决方案链接返回顶部', {}, () => {})
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: 'smooth',
	})
}

// 判断元素是否在视窗之中
export function isInViewPort(element: any) {
	const viewWidth = window.innerWidth || document.documentElement.clientWidth
	const viewHeight = window.innerHeight || document.documentElement.clientHeight
	const {
		top,
		right,
		bottom,
		left,
		width,
		height,
	} = element.getBoundingClientRect()

	return (
		top >= 0 &&
		left >= 0 &&
		right <= viewWidth &&
		top + height / 3 <= viewHeight // 元素顶部露出视窗1/3
	)
}

// 判断当前设备类型 安卓 或 iPhone
export function checkDevice() {
	const ua = window.navigator.userAgent.toLocaleLowerCase()
	const isIOS = /iphone|ipad|ipod/.test(ua)
	const isAndroid = /android/.test(ua)
	return {
		isIOS,
		isAndroid,
	}
}
