const protocol = window.location.protocol
const host = window.location.host
const serverURL = `${protocol}//${host}`

// const DEV_SERVER_URL = 'http://3d-test.vanke.com'
const DEV_SERVER_URL = ''

const envConstant = [
	{
		BASEURL: `${DEV_SERVER_URL}/api`, // 基础接口路径
		WORKSPACEURL: 'http://3d-login-test.vanke.com',
		REDIRECTURL: 'http://3d-login-test.vanke.com',
	},
	{
		BASEURL: `${serverURL}/api`,
		WORKSPACEURL: 'http://3d-login-fat.vanke.com',
		REDIRECTURL: 'http://3d-login-fat.vanke.com',
	},
	{
		BASEURL: `${serverURL}/api`,
		WORKSPACEURL: 'https://3d-login.vanke.com',
		REDIRECTURL: 'https://3d-login.vanke.com',
	},
]

export default function checkEnv() {
	switch (host) {
		// 正式环境
		case 'vtwinning.com':
		case 'www.vtwinning.com':
			return envConstant[2]
		// fat测试环境
		case 'vtwinning-fat.vanke.com':
			return envConstant[1]
		// dev环境
		default:
			return envConstant[0]
	}
}
