export default [
	{
		path: '/',
		component: '@/layouts/index',
		routes: [
			{
				path: '/',
				exact: true,
				component: '@/pages/home',
				// component: '@/pages/gameApp',
			},
			{
				path: '/login',
				exact: true,
				component: '@/pages/login',
			},
			{
				path: '/wechatRelation',
				exact: true,
				component: '@/pages/wechatRelation',
			},
			{
				path: '/register',
				exact: true,
				component: '@/pages/register',
			},
			{
				path: '/resetPwd',
				exact: true,
				component: '@/pages/resetPwd',
			},
			{
				path: '/dataService',
				exact: true,
				component: '@/pages/dataService',
			},
			{
				path: '/aboutUS',
				exact: true,
				component: '@/pages/aboutUS',
			},
			{
				path: '/digitalGarage',
				exact: true,
				component: '@/pages/digitalGarage',
			},
			{
				path: '/digitalSandbox',
				exact: true,
				component: '@/pages/digitalSandbox',
			},
			{
				path: '/digitalExhi',
				exact: true,
				component: '@/pages/digitalExhi',
			},
			{
				path: '/digitalTourism',
				exact: true,
				component: '@/pages/digitalTourism',
			},
			{
				path: '/news',
				exact: true,
				component: '@/pages/news',
			},
			{
				path: '/wingParking',
				exact: true,
				component: '@/pages/wingParking',
			},
			{
				path: '/salesmanTool',
				exact: true,
				component: '@/pages/salesmanTool',
			},
			{
				path: '/404',
				exact: true,
				component: '@/pages/404',
			},
		],
	},
]
