import sha1 from 'crypto-js/sha1'
import sha256 from 'crypto-js/sha256'
import { getCookie } from './utils'
const randomString = (e: number) => {
	e = e || 12
	let t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
		a = t.length,
		n = ''
	for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
	return n
}

function getHeaders(token?: string) {
	let Authorization: any = token ?? getCookie('accesstoken')

	let echostr = randomString(12)

	let timestamp = new Date().getTime()

	let nonce = sha1('qtzUser').toString()

	let signature = sha256(echostr + nonce + timestamp + Authorization).toString()

	return {
		Authorization,
		echostr,
		nonce,
		timestamp,
		signature,
	}
}

export default getHeaders
